import React from "react"
import { useForm } from "react-hook-form"
export default function Demo() {
  const { register, handleSubmit, reset } = useForm()
  const onSubmit = data => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(data).toString(),
    })
      .then(() => {
        reset()
      })
      .catch(error => alert(error))
  }
  return (
    <div className="">
      <div className="py-24 px-8 sm:px-12 rounded flex flex-col  items-center justify-center space-y-8">
        <div className="text-5xl sm:text-6xl font-extrabold text-center">
          Neugierig geworden?
        </div>
        <div className="my-8 text-center text-lg">
          Klicken Sie einfach auf den Button, um direkt eine Demo zu buchen.
        </div>
        <div className="">
          <a
            target="_blank"
            href="https://calendly.com/brantpoint/demo"
            className="text-semibold p-2 px-4 sm:p-4 sm:px-8 bg-yellow-300 hover:bg-yellow-400 transition-color duration-300 ease-in rounded"
          >
            <span>Demo buchen</span>
          </a>
        </div>
        {/* <div className="mb-8 text-center text-lg">
          Gerne zeigen wir Ihnen, wie sich ein Fall aus Ihrer täglichen Praxis
          mit TaxIQ abbilden lässt.
        </div> */}

        {/* <form
          className="flex flex-col sm:flex-row items-center"
          onSubmit={handleSubmit(onSubmit)}
          method="post"
          name="emails"
          data-netlify="true"
          netlify-honeypot="bot-field"
        >
          <input
            {...register("contactEmail")}
            type="email"
            placeholder="Ihre E-Mail ..."
            className="w-full sm:w-auto p-2 border border-blue-atl rounded-t sm:rounded-l sm:rounded-t-none focus:outline-none"
            required={true}
          />
          <input type="hidden" {...register("bot-field")} />
          <input type="hidden" value="emails" {...register("form-name")} />
          <input
            className="w-full sm:w-auto p-2 px-8 rounded-b sm:rounded-b-none sm:rounded-r border border-blue-atl bg-blue-atl text-white hover:bg-blue-900 transition-color duration-300 ease-in"
            value="Absenden"
            type="submit"
          />
        </form> */}
      </div>
    </div>
  )
}
