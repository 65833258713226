import React, { useState } from "react"
import BaseHeading from "../Heading"

export default function Testimonials() {
  const [showTesti, setShowTesti] = useState(1)
  return (
    <div className="relative">
      <BaseHeading heading="Stimmen zu TaxIQ" />
      <div className=" p-8 lg:p-0">
        <div
          className="relative h-full w-full flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-8 bg-gray-100 p-8"
          css={{
            minHeight: "350px",
          }}
        >
          {showTesti === 1 && (
            <React.Fragment>
              <div
                className="w-64 h-64 rounded-full flex flex-col"
                css={{
                  backgroundImage: "url(/Metz_Frank_DZ.webp)",
                  backgroundPosition: "top",
                  backgroundSize: "cover",
                }}
              ></div>
              <div className="flex-1 flex flex-col space-y-4">
                <p className="text-gray-500 text-2xl">
                  "TaxIQ hilft uns, Zeit zu sparen, und bedeutet, dass wir einen
                  schnelleren und besseren Service bieten können."
                </p>
                <span>Frank Metz, Konzernsteuerabteilung, DZ BANK AG</span>
                <img
                  className="rounded h-12 w-48"
                  src="/dzlogo.png"
                  alt="Logo DZ BANK AG"
                />
              </div>
            </React.Fragment>
          )}
          {showTesti === 2 && (
            <React.Fragment>
              <div
                className="w-64 h-64 rounded-full flex flex-col"
                css={{
                  backgroundColor: "#fafafa",
                  backgroundImage: "url(/Hoppe_Thomas_web.png)",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>

              <div className="flex-1 flex flex-col space-y-4">
                <p className="text-gray-500 text-2xl">
                  „Mit TaxIQ lassen sich auch komplexe steuerliche Fragen
                  automatisiert lösen.“
                </p>
                <span>Thomas Hoppe, Mit-Initiator TAXPUNK</span>
                <img
                  className="rounded h-12 w-40"
                  src="/taxpunk_logo.png"
                  alt="Logo TaxPunk"
                />
              </div>
            </React.Fragment>
          )}
          {showTesti === 3 && (
            <React.Fragment>
              <div
                className="w-64 h-64 rounded-full flex flex-col"
                css={{
                  backgroundImage: "url(/wolfdieter.jpeg)",
                  backgroundPosition: "top",
                  backgroundSize: "cover",
                }}
              ></div>
              <div className="flex-1 flex flex-col space-y-4">
                <p className="text-gray-500 text-2xl">
                  "Schnell und zielgerichtet ist TaxIQ das ideale Werkzeug, um
                  individuelle Lösungen zu erstellen."
                </p>
                <span>
                  Dr. Wolf-Dieter Mangold, Leiter Konzernsteuern, Voith
                </span>
                <img
                  className="rounded h-20 w-20"
                  src="/voith.svg"
                  alt="Logo Voith"
                />
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="flex items-center justify-center space-x-4 w-full h-12">
          <button
            className="focus:outline-none w-4 h-4 p-1 border border-blue-atl rounded-full"
            onClick={() => {
              setShowTesti(1)
            }}
          >
            {showTesti === 1 && (
              <div className="w-full h-full bg-blue-atl rounded-full"></div>
            )}
          </button>
          <button
            className="focus:outline-none w-4 h-4 p-1 border border-blue-atl rounded-full"
            onClick={() => {
              setShowTesti(2)
            }}
          >
            {showTesti === 2 && (
              <div className="w-full h-full bg-blue-atl rounded-full"></div>
            )}
          </button>
          <button
            className="focus:outline-none w-4 h-4 p-1 border border-blue-atl rounded-full"
            onClick={() => {
              setShowTesti(3)
            }}
          >
            {showTesti === 3 && (
              <div className="w-full h-full bg-blue-atl rounded-full"></div>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
