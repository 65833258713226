import React from "react"

import IconCheck from "../../assets/iconCheck1.svg"

export default function Hightlights() {
  return (
    <React.Fragment>
      <div className="pt-0 pb-28 text-lg rounded mt-44">
        <div className="p-8 lg:px-0 py-24 font-extrabold text-3xl lg:text-5xl text-blue-atl">
          Highlights
        </div>
        <div className="flex flex-col lg:flex-row bg-gray-100 rounded">
          <div className="p-8 sm:p-16">
            <div className="flex flex-col items-start sm:flex-row sm:items-center mb-4">
              <h1 className="text-3xl font-bold mt-4 sm:mt-0 ">
                No-Code-Lösung
              </h1>
            </div>
            <div className="text-justify">
              Die fachlichen Regeln werden von der Steuerabteilung bzw. von
              Steuerfachleuten entwickelt, in das System eingepflegt und bei
              Bedarf an neue rechtliche Rahmenbedingungen angepasst. Die
              Erstellung und Pflege von TaxIQ-basierten Entscheidungshilfen oder
              Workflows erfordert keinerlei Programmierkenntnisse, sondern
              erfolgt in einem grafischen Editor.
            </div>
          </div>
          <div className="p-8 sm:p-16">
            <div className="flex flex-col items-start sm:flex-row sm:items-center mb-4">
              <h1 className="text-3xl font-bold  mt-4 sm:mt-0 ">
                Automatische Dokumentation
              </h1>
            </div>
            <div className="text-justify">
              Das System dokumentiert selbstständig alle wesentlichen Vorgänge
              automatisch. Durch integrierte Analysemöglichkeiten lässt sich
              noch Jahre später der Nachweis über bestimmte steuerliche
              Entscheidungen erbringen, etwa gegenüber der Finanzverwaltung.
              Damit bleibt zu jedem Zeitpunkt nachvollziehbar Wer, Wann, Welche
              Entscheidung getroffen hat.
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row bg-gray-100 rounded">
          <div className="p-8 sm:p-16">
            <div className="flex flex-col items-start sm:flex-row sm:items-center  mb-4">
              <h1 className="text-3xl font-bold  mt-4 sm:mt-0">
                Integration statt Insellösung
              </h1>
            </div>
            <div className="text-justify">
              TaxIQ sichert Tax Compliance in Echtzeit, auch in steuerfremden
              Abteilungen wie Einkauf oder Vertrieb. Dabei bietet TaxIQ die
              Möglichkeit, steuerliche Vorgaben in die bestehende Prozess- und
              Systemlandschaft des Unternehmens auf einfache Art und Weise zu
              integrieren.{" "}
            </div>
          </div>
          <div className="p-8 sm:p-16">
            <div className="flex flex-col items-start sm:flex-row sm:items-center  mb-4">
              <h1 className="text-3xl font-bold  mt-4 sm:mt-0">Transparenz</h1>
            </div>
            <div className="text-justify">
              Durch die Nutzung von TaxIQ erhalten Sie in Echtzeit Feedback über
              die auftretenden steuerlichen Probleme und deren Lösung und können
              umgehend reagieren. Hierzu steht Ihnen ein Dashboard mit einer
              Vielzahl an Analysemöglichkeiten zur Verfügung.
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
