import React from "react"
import Header from "../components/Header"
import Demo from "../components/sections/Demo"
import Highlights from "../components/sections/Highlights"
import TaxIQIdea from "../components/sections/Idea"
import ExampleServices from "../components/sections/ExampleServices"
import Footer from "../components/Footer"
import { PageProps } from "gatsby"
import Section from "../components/Section"
import Wrapper from "../components/Wrapper"
import Landing1 from "../components/sections/Landing1"
import SEO from "../components/SEO"
import Testimonials from "../components/sections/Testimonials"
export default function Home(props: PageProps) {
  return (
    <React.Fragment>
      <SEO titleAddition="Startseite" />
      <Header />
      <div
        className="w-full overflow-x-hidden flex flex-col text-gray-700"
        css={{
          minHeight: "100vh",
        }}
      >
        <Section>
          <Wrapper>
            <Landing1 />
          </Wrapper>
        </Section>
        <Section>
          <Wrapper>
            <TaxIQIdea />
          </Wrapper>
        </Section>
        <Section>
          <Wrapper>
            <Testimonials />
          </Wrapper>
        </Section>
        <Section>
          <Wrapper>
            <ExampleServices />
          </Wrapper>
        </Section>
        <Section>
          <Wrapper>
            <Highlights />
          </Wrapper>
        </Section>
        <Section>
          <Wrapper>
            <Demo />
          </Wrapper>
        </Section>
        <div className="w-full border-t border-gray-200">
          <div
            css={{
              maxWidth: 1400,
              position: "relative",
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
            }}
          >
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
