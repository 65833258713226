import React from "react"
import BaseHeading from "../Heading"
import IconCheck1 from "../../assets/iconCheck.svg"
export default function TaxIQIdea() {
  return (
    <div className="h-full">
      <BaseHeading heading="TaxIQ in Zahlen" />
      <div
        className="h-full w-full p-8 lg:p-0"
        css={{
          display: "grid",
          gridTemplateRows: "repeat(auto-fill, min-content)",
          gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
          gap: "1rem 1rem",
        }}
      >
        <MyBox header="1" body="Weniger Fehler bei Routineaufgaben" />
        <MyBox header="2" body="Weniger Zeitbedarf bei Standardtätigkeiten" />
        <MyBox header="3" body="Automatische Dokumentation" />
        <MyBox
          header="4"
          body="Unternehmensweite Einhaltung steuerlicher Vorgaben in Echtzeit"
        />
        <MyBox header="5" body="Vermeidung von Medienbrüchen" />
        <MyBox header="6" body="Mehr Zufriedenheit im Team" />
      </div>
    </div>
  )
}

function MyBox({ header, body }: { header: string; body: string }) {
  return (
    <div className="h-64 flex flex-col items-center justify-center px-4 py-4 lg:py-0 bg-gray-100 rounded">
      <div className="flex justify-center mb-8 text-6xl font-bold text-blue-atl">
        {header}
      </div>
      <span className="text-xl font-extrabold text-center">{body}</span>
    </div>
  )
}
