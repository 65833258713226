import React from "react"
export default function Landing() {
  return (
    <div
      className="relative flex flex-col lg:flex-row overflow-hidden rounded"
      css={{
        minHeight: "calc(100vh - 5rem)",
        backgroundImage: `url(/Landing4.jpg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="flex-1 flex flex-col items-start justify-start p-8 lg:p-16 rounded ">
        <div
          className="p-4 rounded"
          css={{
            maxWidth: 400,
          }}
        >
          <div className="text-main">
            <div className="text-8xl font-extrabold ">TaxIQ</div>
            <div className="text-4xl font-bold mt-2">
              Tax Compliance. Automatisiert.
            </div>
            <div className="mt-8">
              <a
                target="_blank"
                href="https://calendly.com/brantpoint/demo"
                className="text-semibold p-2 px-4 sm:p-4 sm:px-8 bg-yellow-300 hover:bg-yellow-400 transition-color duration-300 ease-in rounded"
              >
                <span>Demo buchen</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col p-8 lg:p-16 space-y-4">
        <div className="text-gray-700 animate-bounce text-xl">
          Probieren Sie einen Service aus ...
        </div>
        <Box
          header="Umsatzsteuer"
          body="Steuerbar, steuerpflichtig oder steuerfrei – Bestimme den Ort der sonstigen Leistung."
          url="https://app02.viewer.taxiq.io/realms/stage/services/init/e63a4f48-7b46-4985-bc35-ca2a21b5a695/d5de214b-9e2f-40b9-9eaa-668af2da6917"
        />
        {/* <Box
          header="Besteuerung von Elektroautos"
          body="0,25, 0,5 oder 1 % – Ermittle die korrekte Besteuerung von E-Autos."
          url=""
        />
        <Box
          header="Workation"
          body="Betriebsstätte, Lohnsteuer und Sozialversicherung – Was sind die steuerlichen Folgen der mobilen Arbeit?"
          url=""
        />
        <Box
          header="Steuerrelevante Daten im Ausland"
          body="Cloud, EU oder Drittland – Was gilt bei der Archivierung steuerlicher Datenbestände im Ausland?"
          url=""
        /> */}
      </div>
    </div>
  )
}

function Box({
  header,
  body,
  url,
}: {
  header: string
  body: string
  url: string
}) {
  return (
    <div className="text-left p-4  bg-white/50 hover:bg-white rounded transition-color ease-in duration-300 space-y-2">
      <div className="text-2xl font-bold">{header}</div>
      <div>{body}</div>
      <a href={url} target="_blank" className="text-blue-atl text-xs">
        Klicken und ausprobieren
      </a>
    </div>
  )
}
