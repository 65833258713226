import React from "react"
import BaseHeading from "../Heading"
import Idea1 from "../../assets/idea1.svg"
import Idea2 from "../../assets/idea2.svg"
import Idea3 from "../../assets/idea3.svg"
import Idea4 from "../../assets/idea4.svg"
export default function TaxIQIdea() {
  return (
    <div className="h-full">
      <div className="mt-28 h-full flex flex-col xl:flex-row text-4xl lg:text-6xl font-semibold p-8 italic">
        „TaxIQ entlastet die Steuer- und Finanzabteilung spürbar.“
      </div>
    </div>
  )
}
